import homeBanner1 from "../images/home/home-1.webp"
import homeBanner2 from "../images/home/home-2.webp"
import homeBanner3 from "../images/home/home-3.webp"

import pic1 from "../images/blog/grid/pic1.jpg"
import pic2 from "../images/blog/grid/pic2.jpg"
import pic3 from "../images/blog/grid/pic3.jpg"

import abt1 from "../images/about/carousel/1.webp"
import abt2 from "../images/about/carousel/2.webp"
import abt3 from "../images/about/carousel/3.webp"
import abt4 from "../images/about/carousel/4.webp"
import abt6 from "../images/about/carousel/6.webp"
import abt7 from "../images/about/carousel/7.webp"
import abt8 from "../images/about/carousel/8.webp"
import abt9 from "../images/about/carousel/9.webp"
import abt10 from "../images/about/carousel/10.webp"
import abt11 from "../images/about/carousel/11.webp"
import abt12 from "../images/about/carousel/12.webp"
import abt13 from "../images/about/carousel/13.webp"
import abt14 from "../images/about/carousel/14.webp"
import abt15 from "../images/about/carousel/15.webp"
import abt16 from "../images/about/carousel/16.webp"
import abt17 from "../images/about/carousel/17.webp"
import abt19 from "../images/about/carousel/19.webp"
import abt20 from "../images/about/carousel/20.webp"
import abt21 from "../images/about/carousel/21.webp"
import abt22 from "../images/about/carousel/22.webp"
import abt25 from "../images/about/carousel/25.webp"
import abt26 from "../images/about/carousel/26.webp"
import abt27 from "../images/about/carousel/27.webp"

import cer1 from "../images/about/qualifications/cer-1.webp"
import cer2 from "../images/about/qualifications/cer-2.webp"
import cer3 from "../images/about/qualifications/cer-3.webp"
import cer4 from "../images/about/qualifications/cer-4.webp"
import cer5 from "../images/about/qualifications/cer-5.webp"
import cer6 from "../images/about/qualifications/cer-6.webp"
import cer7 from "../images/about/qualifications/cer-7.webp"
import cer8 from "../images/about/qualifications/cer-8.webp"
import cer9 from "../images/about/qualifications/cer-9.webp"
import cer10 from "../images/about/qualifications/cer-10.webp"
import cer11 from "../images/about/qualifications/cer-11.webp"
import cer12 from "../images/about/qualifications/cer-12.webp"
import cer13 from "../images/about/qualifications/cer-13.png"
import cer14 from "../images/about/qualifications/cer-14.png"
import cer15 from "../images/about/qualifications/cer-15.png"
import cer16 from "../images/about/qualifications/cer-16.png"

import rec1 from "../images/about/recognition/recog-1.webp"
import rec2 from "../images/about/recognition/recog-2.webp"
import rec3 from "../images/about/recognition/recog-3.webp"
import rec4 from "../images/about/recognition/recog-4.webp"
import rec5 from "../images/about/recognition/recog-5.webp"
import rec6 from "../images/about/recognition/recog-6.webp"
import rec7 from "../images/about/recognition/recog-7.webp"
import rec8 from "../images/about/recognition/recog-8.webp"
import rec9 from "../images/about/recognition/recog-9.webp"

// recognitions
export const servicesContentCustom2 = [
  {
    image: rec9,
    description:
      "Grand Winner Award for PT Kalbio Global Medika as Best Biologics CMO in Southeast Asia at Asia Pacific Biologics CMO Excellence Awards 2023",
    serviceName: "2023 Best Biologics CMO in Southeast Asia",
    deskripsi:
      "Penghargaan Grand Winner bagi PT Kalbio Global Medika sebagai CMO Biologis Terbaik se-Asia Tenggara di Asia Pacific Biologics CMO Excellence Awards 2023",
    nama: "CMO Biologis Terbaik di Asia Tenggara 2023",
    deskripsiCN:
      "PT Kalbio Global Medika 在 2023 年亚太生物制品 CMO 卓越奖中荣获东南亚最佳生物制品 CMO 大奖",
    namaCN: "2023 年东南亚最佳生物制品 CMO",
  },
  {
    image: rec7,
    description:
      "Appreciation from Regulatory & Quality Forum X to PT Kalbio Global Medika (Mab Line) regarding GMP Certification for Pharmaceutical",
    serviceName: "2021 GMP Certification for Pharmaceutical",
    deskripsi:
      "Apresiasi dari Regulatory & Quality Forum X kepada PT Kalbio Global Medika (Mab Line) mengenai Sertifikasi GMP untuk Farmasi",
    nama: "Sertifikat GMP untuk Farmasi 2021",
    deskripsiCN:
      "监管与质量论坛 X 对 PT Kalbio Global Medika (Mab Line) 关于药房 的GMP 认证表示赞赏",
    namaCN: "2021 年药房 GMP 证书",
  },
  {
    image: rec8,
    description:
      "Appreciation from Regulatory & Quality Forum X to PT Kalbio Global Medika in recognition of compliance to Kalbe Corporate Manual",
    serviceName: "2021 100% Integration to Corporate Regulatory Manual",
    deskripsi:
      "Apresiasi dari Regulatory & Quality Forum X kepada PT Kalbio Global Medika sebagai pengakuan atas kepatuhan terhadap Manual Korporat Kalbe",
    nama: "100% Integrasi pada Manual Regulatori Korporat 2021",
    deskripsiCN:
      "监管与质量论坛 X 就 PT Kalbio Global Medika 对 Kalbe 的企业手册之遵守进行表彰",
    namaCN: "2021年企业监管手册100%整合",
  },
  {
    image: rec6,
    description:
      "Award for PT Kalbio Global Medika in achieving the completion of Quality Assurance Agreement",
    serviceName: "2019 Regulatory & Quality Award",
    deskripsi:
      "Penghargaan bagi PT Kalbio Global Medika dalam usahanya mencapai penyelesaian Perjanjian Jaminan Kualitas",
    nama: "Penghargaan Regulatori & Kualitas 2019",
    deskripsiCN:
      "授予 PT Kalbio Global Medika 奖项， 表彰其为完成质量保证协议所做出的努力",
    namaCN: "2019 年监管与质量奖",
  },
  {
    image: rec1,
    description:
      "Award from IMAPAC for recognition to PT Kalbio Global Medika as exceptional bioprocessing experts, organizations and technologies that facilitate biomanufacturing excellence at enhanced speed, reduced cost, and superior quality",
    serviceName: "2019 IMAPAC Bioprocessing Award",
    deskripsi:
      "Pengakuan sebagai pakar, organisasi, dan teknologi bioproses luar biasa yang memfasilitasi keunggulan biomanufaktur dengan peningkatan kecepatan, pengurangan biaya, dan kualitas unggul",
    nama: "Penghargaan Bioprocessing IMAPAC 2019",
    deskripsiCN:
      "被认可为通过提高速度、降低成本和卓越质量促进卓越生物制造的杰出生物工艺专家、组织和技术",
    namaCN: "2019 年 IMAPAC 生物工艺奖",
  },
  {
    image: rec3,
    description:
      "Award from Ministry of Health of the Republic of Indonesia for institution which contributes in the development of health industry",
    serviceName:
      "2018 Employee Awards for Domestic Creation in Pharmaceuticals and Health Equipments",
    deskripsi:
      "Penghargaan dari Kementerian Kesehatan Republik Indonesia untuk lembaga yang berkontribusi dalam pengembangan industri kesehatan",
    nama:
      "Penghargaan Karyawan Untuk Kreasi Dalam Negeri Di Bidang Farmasi Dan Alat Kesehatan 2018",
    deskripsiCN:
      "印度尼西亚共和国卫生部颁发给为健康产业发展做出贡献的机构之奖项",
    namaCN: "2018年制药及医疗器械领域国内创作员工奖",
  },
  {
    image: rec5,
    description:
      "Appreciation for PT Kalbio Global Medika as the process optimization in EPO production team at Kalbe Group innovation convention for process optimization in EPO production",
    serviceName: "2018 Innovation Convention Appreciation",
    deskripsi:
      "Apresiasi untuk PT Kalbio Global Medika sebagai tim Process Optimization in EPO Production pada Konvensi Inovasi Kalbe Group untuk optimasi proses produksi EPO",
    nama: "Apresiasi Konvensi Inovasi 2018",
    deskripsiCN:
      "表彰 PT Kalbio Global Medika 在 Kalbe 集团创新大会上作为 EPO 生产工艺优化团队，优化 EPO 生产工艺",
    namaCN: "2018创新大会鉴赏",
  },
  {
    image: rec2,
    description:
      "Honorable Mention award to PT Kalbio Global Medika from International Society for Pharmaceutical Engineering (ISPE)",
    serviceName: "2017 ISPE Facility of the Year",
    deskripsi:
      "Penghargaan Honorable Mention kepada PT Kalbio Global Medika dari International Society for Pharmaceutical Engineering (ISPE)",
    nama: "Facility of the Year ISPE 2017",
    deskripsiCN:
      "国际制药工程学会 (ISPE) 向 PT Kalbio Global Medika 颁发了荣誉奖",
    namaCN: "ISPE 2017年度设施",
  },
  {
    image: rec4,
    description:
      "Award for PT Kalbio Global Medika as the most promising new biologics facility in Indonesia at the Asia-Pacific Bioprocessing Excellence Awards 2017",
    serviceName: "2017 Most Promising New Biologics Facility in Indonesia",
    deskripsi:
      "Penghargaan untuk PT Kalbio Global Medika sebagai fasilitas biologi baru paling menjanjikan di Indonesia pada Asia-Pacific Bioprocessing Excellence Awards 2017",
    nama: "Fasilitas Biologi Baru Paling Menjanjikan di Indonesia 2017",
    deskripsiCN:
      "PT Kalbio Global Medika 在 2017 年亚太生物工艺卓越奖中荣获印度尼西亚最有前景的新型生物设施奖项",
    namaCN: "2017 年印度尼西亚最有前景的新型生物设施",
  },
]

// qualifications
export const servicesContentCustom1 = [
  {
    image: cer12,
    description:
      "Commitment to prevent accident, occupational health illness, and environmental pollution through implementation of OHSE Management System and to meet international standard of ISO 14001:2015 & ISO 45001:2018",
    serviceName: "Policy of OHSE",
    deskripsi:
      "Sebagai komitmen perusahaan untuk menerapkan sistem manajemen K3 dan Lingkungan sesuai standard internasional ISO 14001:2015 & ISO 45001:2018",
    nama: "Kebijakan Keselamatan Kesehatan Kerja (K3) dan Lingkungan",
    deskripsiCN:
      "作为公司的承诺，将按照国际标准 ISO 14001:2015 和 ISO 45001:2018 实施职业健康安全（OHSE）和环境管理体系",
    namaCN: "职业健康安全（OHSE）和环境政策",
  },
  {
    image: cer13,
    description:
      "Certificate of ISO 14001:2015 (Environmental Management System) and ISO 45001:2018 (OHS Management System), issued by Business Systems Certification accredited by JAS-ANZ (Joint Accreditation System of Australia & New Zealand)",
    serviceName: "ISO 14001:2015 & 45001:2018",
    deskripsi:
      "Sertifikat ISO 14001:2015 (Sistem Manajemen Lingkungan) dan ISO 45001:2018 (Sistem Manajemen K3), dikeluarkan oleh badan sertifikasi Business Systems Certification yang terakreditasi oleh JAS-ANZ (Joint Accreditation System of Australia & New Zealand)",
    nama: "ISO 14001:2015 & 45001:2018",
    deskripsiCN:
      "由JAS-ANZ（澳大利亚和新西兰联合认证体系）认可的商业系统认证机构颁发的ISO 14001:2015（环境管理体系）和ISO 45001:2018（职业健康安全管理体系）证书",
    namaCN: "ISO 14001:2015 & 45001:2018",
  },
  {
    image: cer14,
    description: "Quality Assurance Audit Certificate from PCI Pharma Services",
    serviceName: "EU GMP Compliance",
    deskripsi: "Sertifikat Audit Jaminan Kualitas dari PCI Pharma Services",
    nama: "Kepatuhan Terhadap <i>EU GMP</i>",
    deskripsiCN:
      "PCI Pharma Services 的质量保证审核证书",
    namaCN: "欧盟 GMP 合规性",
  },
  // {
  //   image: cer6,
  //   description:
  //     "Investigational Medicinal Products (IMP) Manufactured in Third countries (article 13(3)(b) of directive 2001/20/EC. IMP Includes: Hemapo, Efepoetin and Epoetin-alpha injection",
  //   serviceName: "Qualified Person's Declaration Equivalence to EU GMP",
  //   deskripsi:
  //     "Produk Obat Investigasi (IMP) yang diproduksi di Third countries (pasal 13(3)(b) direktif 2001/20/EC). IMP Termasuk: Injeksi Hemapo, Efepoetin dan Epoetin-alpha",
  //   nama: "Deklarasi <i>Qualified Person</i> yang setara dengan <i>EU GMP</i>",
  //   deskripsiCN:
  //     "在第三国生产的研究药物产品 (IMP)（指令 2001/20/EC 第 13(3)(b) 条）。 IMP 包括：Hemapo、Ephepoetin 和 Epoetin-alpha 注射液",
  //   namaCN: "相当于欧盟 GMP 的合格人员声明",
  // },
  {
    image: cer16,
    description:
      "For addendum of manufacturing facility for Biological Active Pharmaceutical Ingredients Efepoetin alfa for batch size 50L and 1000L from National Agency for Drug and Food Control Republic of Indonesia (BPOM)",
    serviceName:
      "Addendum to Manufacturing Facility for API",
    deskripsi:
      "Untuk perubahan pada fasilitas produksi Bahan Baku Aktif Obat Biologi Efepoetin alfa untuk bets ukuran 50L dan 1000L dari Badan Pengawas Obat dan Makanan Republik Indonesia (BPOM)",
    nama: "Perubahan Fasilitas Produksi Bahan Baku Aktif Obat",
    deskripsiCN:
      "用于生物活性原料成分 Efepoetin alfa 的生产设施附录，批量为 50L 和 1000L， 由印度尼西亚共和国食品药品监督管理局（BPOM）所颁发",
    namaCN: "活性药物原料生产设施附录（API）",
  },
  {
    image: cer10,
    description:
      "For Biological Active Pharmaceutical Ingredients Efepoetin Alfa manufacturing from National Agency for Drug and Food Control Republic of Indonesia (BPOM)",
    serviceName:
      "Good Manufacturing Practice for Active Pharmaceutical Ingredients (API)",
    deskripsi:
      "Untuk pembuatan Bahan Baku Aktif Biologi Efepoetin Alfa dari Badan Pengawas Obat dan Makanan Republik Indonesia (BPOM)",
    nama: "Cara Pembuatan Bahan Baku Aktif Obat yang Baik (CPBBAOB)",
    deskripsiCN:
      "用于制造Efepoetin Alfa生物活性原料， 由印度尼西亚共和国食品药品监督管理局（BPOM）所颁发",
    namaCN: "活性药物原料良好生产规范（API）",
  },
  {
    image: cer11,
    description:
      "For Biological Active Pharmaceutical Ingredients Erythropoietin manufacturing from National Agency for Drug and Food Control Republic of Indonesia (BPOM)",
    serviceName:
      "Good Manufacturing Practice for Active Pharmaceutical Ingredients (API)",
    deskripsi:
      "Untuk pembuatan Bahan Baku Aktif Biologi Eritropoietin dari Badan Pengawas Obat dan Makanan Republik Indonesia (BPOM)",
    nama: "Cara Pembuatan Bahan Baku Aktif Obat yang Baik (CPBBAOB)",
    deskripsiCN:
      "用于制造促红细胞生成素生物活性原料，由印度尼西亚共和国食药品监督管理局（BPOM）所颁发",
    namaCN: "活性药物原料良好生产规范（API）",
  },
  {
    image: cer9,
    description:
      "For Biotechnology Products Non-vaccine from Indonesian Food and Drug Authority (BPOM)",
    serviceName:
      "Good Manufacturing Practice for Biotechnology Products Non-vaccine of Pre-Filled Syringe and Cartridge",
    deskripsi:
      "Untuk Produk Bioteknologi non vaksin dari Badan Pengawas Obat dan Makanan Republik Indonesia (BPOM)",
    nama:
      "Cara Pembuatan Obat yang Baik (CPOB) untuk Produk Bioteknologi non vaksin Pre-Filled Syringe and Cartridge",
    deskripsiCN:
      "用于非疫苗生物技术产品，印度尼西亚共和国食品药品监督管理局（BPOM）所颁发",
    namaCN: "非疫苗预充式注射器和药筒生物技术产品良好药品生产规范证书",
  },
  {
    image: cer8,
    description:
      "For Biotechnology Products Non-vaccine from Indonesian Food and Drug Authority (BPOM)",
    serviceName:
      "Good Manufacturing Practice for Biotechnology Products Non-vaccine of Monoclonal Antibody Vial",
    deskripsi:
      "Untuk Produk Bioteknologi non vaksin dari Badan Pengawas Obat dan Makanan Republik Indonesia (BPOM)",
    nama:
      "Cara Pembuatan Obat yang Baik (CPOB) untuk Produk Bioteknologi non vaksin Vial Antibodi Monoklonal",
    deskripsiCN:
      "用于非疫苗生物技术产品，印度尼西亚共和国食品药品监督管理局（BPOM）所颁发",
    namaCN: "非疫苗生物技术产品单克隆抗体瓶的良好药品生产规范证书",
  },
  {
    image: cer7,
    description:
      "Commitment to the delivery of safe and effective products and services to patients and consumers",
    serviceName: "Corporate Quality Policy",
    deskripsi:
      "Komitmen untuk memberikan produk dan layanan yang aman dan efektif kepada pasien dan konsumen",
    nama: "Kebijakan Kualitas Korporat",
    deskripsiCN:
      "承诺为患者和消费者提供安全有效的产品和服务",
    namaCN: "企业质量方针",
  },
  {
    image: cer4,
    description:
      "Initiatives of PT Kalbio Global Medika to ensure the product ingredients and manifacturing process are comply to halal",
    serviceName: "Halal Compliance",
    deskripsi:
      "Inisiatif dari PT Kalbio Global Medika untuk memastikan bahan produk dan proses pembuatannya sesuai dengan kebijakan halal",
    nama: "Kepatuhan Kebijakan Halal",
    deskripsiCN:
      "PT Kalbio Global Medika 发起的一项举措，旨在确保产品材料和其制造工艺符合清真（Halal）政策",
    namaCN: "清真（Halal）政策合规性",
  },
  {
    image: cer15,
    description:
      "Halal certificate for the biologics products manufactured by PT Kalbio Global Medika, issued by Indonesian Ulema Council",
    serviceName: "Halal Certificate",
    deskripsi:
      "Sertifikat halal untuk produk biologi yang diproduksi oleh PT Kalbio Global Medika, dikeluarkan oleh Majelis Ulama Indonesia (MUI)",
    nama: "Sertifikat Halal",
    deskripsiCN:
      "印度尼西亚乌里玛理事会为 PT Kalbio Global Medika 生产的生物制品颁发清真证书",
    namaCN: "清真（Halal）证书",
  },
]

export const homeSliderContent2 = [
  {
    image: abt1,
    title: "",
    title2: "",
  },
  {
    image: abt2,
    title: "",
    title2: "",
  },
  {
    image: abt3,
    title: "",
    title2: "",
  },
  {
    image: abt4,
    title: "",
    title2: "",
  },
  {
    image: abt6,
    title: "",
    title2: "",
  },
  {
    image: abt7,
    title: "",
    title2: "",
  },
  {
    image: abt8,
    title: "",
    title2: "",
  },
  {
    image: abt9,
    title: "",
    title2: "",
  },
  {
    image: abt10,
    title: "",
    title2: "",
  },
  {
    image: abt11,
    title: "",
    title2: "",
  },
  {
    image: abt12,
    title: "",
    title2: "",
  },
  {
    image: abt13,
    title: "",
    title2: "",
  },
  {
    image: abt14,
    title: "",
    title2: "",
  },
  {
    image: abt15,
    title: "",
    title2: "",
  },
  {
    image: abt16,
    title: "",
    title2: "",
  },
  {
    image: abt17,
    title: "",
    title2: "",
  },
  {
    image: abt19,
    title: "",
    title2: "",
  },
  {
    image: abt20,
    title: "",
    title2: "",
  },
  {
    image: abt21,
    title: "",
    title2: "",
  },
  {
    image: abt22,
    title: "",
    title2: "",
  },
  {
    image: abt25,
    title: "",
    title2: "",
  },
  {
    image: abt26,
    title: "",
    title2: "",
  },
  {
    image: abt27,
    title: "",
    title2: "",
  },
]

export const HomeBannerSliderContent = [
  {
    image: homeBanner1,
    title: "Kalbio Global",
    title2: "Medika",
  },
  {
    image: homeBanner2,
    title: "Kalbio Global",
    title2: "Medika",
  },
  {
    image: homeBanner3,
    title: "Kalbio Global",
    title2: "Medika",
  },
]
